export default {
  title: "Tournaments",
  description:
    "ITU LAN is hosting several tournaments and events during the weekend, and here you can see an overview over the tournaments and event. Are you up for the challenge?",
  notPublic: "Tournaments to be announced...",
  clickAndRegister: "Click and register",
  by: "powered by",
  tournamentCards: {
    descriptionTBA: "Description TBA",
    platformLabel: "Platform: ",
    tournaments: [
      {
        title: "Civilization VI",
        toornUrl: "https://play.toornament.com/en_GB/tournaments/8637919328462045184/",
        platform: "PC",
        description: "Are you a fan of strategy games? Do you enjoy making fragile alliances with other players, only to betray them when they are most vulnerable? Do you enjoy pitting the whole world against one player, or in turn having the whole world pitted against you? Well then you'll enjoy this semesters ITU LAN tournament in Sid Meier's Civilization VI.</br></br>The game will be played in two parts, from 19:00 to 23:00 on Friday and Saturday.</br></br>Empires will be drafted on the day of, so unfortunately, little planning before the tournament will be possible.</br></br>Note that the expansions \"Rise and Fall\" and \"Gathering Storm\" are necessary for participation.</br></br>The mod list for the game can be found <a href=\"https://steamcommunity.com/sharedfiles/filedetails/?id=3045475898\">here</a>",
        logoPath: "",
      },
      {
        title: "Escape Room Sat",
        toornUrl: "https://calendly.com/itu-escape/escape-room-booking",
        platform: "IRL",
        description: "Step into the world of ancient legends, mighty gods, and fearsome beasts in an escape room experience like no other! 🏛️</br></br>To earn your freedom, you must complete a series of divine trials set by the Olympian gods themselves. Solve riddles posed by Athena, listen to the sirens' song and navigate the Labyrinth of the Minotaur 🔒</br></br>⏳ You have just 45 minutes to prove your worth and escape the clutches of Olympus... or be trapped for eternity!</br></br>Are you ready, hero? The gods await... 🏺🌟🧩</br></br>🔗 Sign up here --> <a target=\"_blank\"  href=\"https://calendly.com/itu-escape/escape-room-booking\">here</a>\"</br></br>Note: Remember you need a LAN ticket to participate in the escape room",
        logoPath: "",
      },
      {
        title: "Magic the Gathering Draf",
        toornUrl: "https://play.toornament.com/en_GB/tournaments/8636265127849541632/",
        platform: "Table Top",
        description: "**Please use your real name when registering!**</br></br>Greetings people \\[T]/</br>We're doing MTG tournament at ITU LAN yet again.</br>More information will follow.",
        logoPath: "",
      },
      {
        title: "Teamfight Tactics",
        toornUrl: "https://play.toornament.com/en_GB/tournaments/8635230389846835200/",
        platform: "PC",
        description: "Welcome to the first ever TFT tournament at ITU LAN!!</br></br>If you are joining please use your Riot Account Name as it makes it easier to figure out each other's summoner name!</br></br>This tournament will be playing on PC and will have a total of 16 participants.</br></br>You will be required to have a EUW account as this is the region we will be playing in.</br></br>Otherwise join the ITU LAN discord in order to get more info or if you have any questions. Hope to see you join!!</br></br>Please check the rules for information about how the tournament is structured!",
        logoPath: "",
      },
      {
        title: "Mario Kart",
        toornUrl: "https://play.toornament.com/en_GB/tournaments/8632335306561134592/",
        platform: "Nintendo Switch",
        description: "Welcome to this semesters Mario Kart tournament, sponsored by Accenture!</br></br>We are happy to bring back this lovely tournament that you all love each semester!</br>The tournament is a second elimination setup, so you will get a redemption chance if you don't make it the first time!</br>When all 16 slots have been filled we'll create lineup.</br></br>For any questions don't be afraid to contact us on the mail or write to us in the discord channel.</br></br>Hope to see you at the tournament!",
        logoPath: "",
      },
      {
        title: "Plate up",
        toornUrl: "https://play.toornament.com/en_US/tournaments/8652293204689305600/",
        platform: "PC",
        description: "The plateup tournament will work in a \"best run wins\" sort of way.</br></br>When the tournament starts, we will publish a seed and recipe that you should use. After that you have 4h to get the best run you possibly can and \"submit\" it in the discord (more details on the day). We will then meet up so each group can present their restaurant and we can crown the winner.</br></br>Teams are 1-2 people, at least one team member has to be level 7.</br></br>If you have any questions, you can reach out on Discord to kydonia or iceplunker.",
        logoPath: "",
      },
      {
        title: "Beat Saber (Novice)",
        toornUrl: "https://play.toornament.com/en_US/tournaments/8655460653743955968/",
        platform: "VR Headset",
        description: "Are you an expert at slicing blocks apart with</br>preposterously advanced light saber weaponry at high speed intervals? Just looking for some casual fun with friends? Or feel the need to get in a bit of exercise away from the chosen gaming chair of the weekend?</br></br>Then come and join us Spring 2025 LAN at the console corner first floor in the ITU building on the 28th of march at 20:00 / 8PM Friday, and become the champion amongst the challengers, or go out in the glorious blaze trying!</br></br>Check-in is an hour earlier at 19:00 / 7PM, after which we settle on the final tournament structure, there will be a check-in notification over LAN's Discord.</br></br>Registration is on a first-come-first-served principle.</br>- Sign up early to secure your spot!</br>- Remember to read the rules if you sign up!",
        logoPath: "",
      },
      {
        title: "Beat Saber (Expert)",
        toornUrl: "https://play.toornament.com/en_US/tournaments/8655525664808509440/",
        platform: "VR headset",
        description: "Are you an expert at slicing blocks apart with</br>preposterously advanced light saber weaponry at high speed intervals? Just looking for some casual fun with friends? Or feel the need to get in a bit of exercise away from the chosen gaming chair of the weekend?</br></br>Then come and join us Spring 2025 LAN at the console corner first floor in the ITU building on the 29th of march at 20:00 / 8PM Friday, and become the champion amongst the challengers, or go out in the glorious blaze trying!</br></br>Check-in is an hour earlier at 19:00 / 7PM, after which we settle on the final tournament structure, there will be a check-in notification over LAN's Discord.</br></br>Registration is on a first-come-first-served principle.</br>- Sign up early to secure your spot!</br>- Remember to read the rules if you sign up!",
        logoPath: "",
      },
      {
        title: "Catan",
        toornUrl: "https://play.toornament.com/en_US/tournaments/8655680569766330368/",
        platform: "Table top",
        description: "Welcome to Catan settlers, you want to show off your resource gathering skills, knight gathering and road building?</br></br>Then this tournament is for you.</br></br>We will be providing the board games.</br></br>This tournament will be divided into three rounds, the top 2 winners of each board will move on to semifinals and the</br>",
        logoPath: "",
      },
      {
        title: "Tekken 8",
        toornUrl: "https://play.toornament.com/en_GB/tournaments/8670032691804839936/",
        platform: "Playstaytion",
        description: "Welcome to this semester's TEKKEN 8 tournament!</br></br>We're doing double elimination, meaning you will have more than one shot to get a price!</br></br></br>So get ready to duke it out with friendly competitors in TEKKEN 8! No previous fighting game experience is required for participation, so grab a friend and join in on the fun!",
        logoPath: "",
      },
      // {
      //   title: "",
      //   toornUrl: "",
      //   platform: " ",
      //   description: "",
      //   logoPath: "",
      // },
    ],
  },
}