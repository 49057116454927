import { render, staticRenderFns } from "./SponsorPage.vue?vue&type=template&id=178eba18&scoped=true&"
var script = {}
import style0 from "./SponsorPage.vue?vue&type=style&index=0&id=178eba18&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "178eba18",
  null
  
)

export default component.exports